body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.formulario-abrigo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 600px; /* Ajuste conforme necessário */
  margin: auto;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  background-color: white;
  border-radius: 8px;
  margin-top: 40px;
}

.formulario-abrigo label {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 16px;
}

.formulario-abrigo input,
.formulario-abrigo button {
  width: calc(100% - 20px); /* Subtrai padding se necessário */
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.formulario-abrigo button {
  background-color: #007BFF;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%; /* Subtrai padding se necessário */
}

.formulario-abrigo button:hover {
  background-color: #0056b3;
}