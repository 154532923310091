.App {
  text-align: center;
  margin-top: 20px;
  margin-left: 40px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-container {
  display: flex;
  justify-content: center; /* Alinha horizontalmente ao centro */
  align-items: center;     /* Alinha verticalmente ao centro */
  padding: 10px 200px;
  flex-direction: column;
  margin: 0;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.button {
  background-color: #007BFF; /* Cor de fundo */
  color: white; /* Cor do texto */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.button:hover {
  background-color: #0056b3; /* Cor ao passar o mouse */
}

.container {
  padding: 20px;
  margin: auto;
}
.row {
  display: flex;
  gap: 20px; /* Espaçamento entre elementos */
}

/* App.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f6f5;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75vh;
}

.content {
  flex: 1;
  padding: 20px;
}

h1 {
  font-size: 2.5em;
  color: #333;
}

p {
  font-size: 1.2em;
  color: #666;
}

.buttons {
  margin-top: 20px;
}

.btn-primary,
.btn-secondary {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  cursor: pointer;
  font-size: 1em;
}

.btn-primary {
  background-color: #ff0000;
  color: white;
}

.btn-secondary {
  background-color: transparent;
  color: #ff0000;
  border: 2px solid #ff0000;
}

.image {
  flex: 1;
  padding: 20px;
}
@media (max-width: 900px) {
  .image {
    display: none;
  }
  .app-container {
    padding: 0px;
  }
}
.image img {
  /* max-width: 100%; */
  height: 40vh;
  border-radius: 40px;
}
.map div {
  width: 100% !important;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}